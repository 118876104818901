import { useEffect, useState } from 'react';

import { createFullObjectFile } from '@shared/utils/dom';
import useLocalStorage from '@hooks/useLocalStorage';

import config from '@config';

export function useCertificate(id: string, type: string | null = null) {
  const [token] = useLocalStorage('authorization');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<object | null>(null);

  async function generateCertificate() {
    try {
      if (!id) return;
      setIsLoading(true);

      const url = [config.api.url, 'certificates', type, id].filter(Boolean).join('/');
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('errors.invalid-request');
      }

      createFullObjectFile(await response.blob(), `certificate-${id}.pdf`);
    } catch (err) {
      setError(err as object);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    (async () => {
      await generateCertificate();
    })();
  }, []);

  return { isLoading, error } as const;
}
