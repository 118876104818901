import React from 'react';
import { PageProps } from 'gatsby';
import { useTranslation } from 'react-i18next';

import { Loader } from '@shared/ui/loader/Loader';
import { useCertificate } from '@shared/hooks/useCertificate';
import { LoaderWrapper } from '@shared/ui/loader/LoaderWrapper';

const UserCertificatePage = (props: PageProps) => {
  const { id } = props.params;

  const { t } = useTranslation();
  const { error, isLoading } = useCertificate(id, 'gratitude');

  if (error) {
    return (
      <div className="min-h-[200px]">
        <div className="absolute px-5 py-5 text-center flex flex-col grow w-full h-full items-center justify-center">
          <div>{t('errors.bad-certificate')}.</div>
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <LoaderWrapper className="min-h-[200px]">
        <Loader />
      </LoaderWrapper>
    );
  }

  return null;
};

export default UserCertificatePage;
